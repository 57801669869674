import { useState, useEffect, useRef } from "react";
import "./App.css";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from "./components/ui/navigation-menu";
import { navigationMenuTriggerStyle } from "./components/ui/navigation-menu";
import { cn } from "./lib/utils";
import LoadingPage from "./LoadingPage";
import About from "./pages/About";
import Experience from "./pages/Experience";
import Portfolio from "./pages/Portfolio";

type Section = "about" | "experience" | "portfolio";

interface NavBarProps {
  activeSection: Section;
  scrollTo: (section: Section) => void;
}

function NavBar({ activeSection, scrollTo }: NavBarProps) {
  return (
    <NavigationMenu className="navigation-menu bg-gray-200 dark:bg-transparent fixed top-0 left-0 right-0 z-50">
      <NavigationMenuList className="navigation-menu-list">
        {["about", "experience", "portfolio"].map((section) => (
          <NavigationMenuItem className="navigation-menu-item" key={section}>
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "transition-colors duration-300",
                activeSection === section
                  ? "bg-primary text-primary-foreground"
                  : "hover:bg-muted"
              )}
              onClick={() => scrollTo(section as Section)}
            >
              {section}
            </NavigationMenuLink>
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

function App() {
  const [dark, setDark] = useState(false);
  const [loadingState, setLoadingState] = useState<
    "loading" | "sliding" | "done"
  >("loading");
  const [initialLoad, setInitialLoad] = useState(true);
  const [activeSection, setActiveSection] = useState<Section>("about");

  const aboutRef = useRef<HTMLElement>(null);
  const experienceRef = useRef<HTMLElement>(null);
  const portfolioRef = useRef<HTMLElement>(null);

  const navbarHeight = 64; // Adjust this value based on your navbar's actual height

  const darkModeHandler = () => {
    const newDark = !dark;
    setDark(newDark);
    document.body.classList.toggle("dark", newDark);
  };

  useEffect(() => {
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    setDark(prefersDarkMode);
    document.body.classList.toggle("dark", prefersDarkMode);

    if (initialLoad) {
      const timer1 = setTimeout(() => {
        setLoadingState("sliding");
      }, 2400);

      const timer2 = setTimeout(() => {
        setLoadingState("done");
        setInitialLoad(false);
      }, 2900);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }
  }, [initialLoad]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + navbarHeight + 1; // Add 1px to account for potential rounding errors

      const experienceTop = experienceRef.current?.offsetTop ?? 0;
      const portfolioTop = portfolioRef.current?.offsetTop ?? 0;

      if (scrollPosition < experienceTop) {
        setActiveSection("about");
      } else if (scrollPosition < portfolioTop) {
        setActiveSection("experience");
      } else {
        setActiveSection("portfolio");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollTo = (section: Section) => {
    const refs = {
      about: aboutRef,
      experience: experienceRef,
      portfolio: portfolioRef,
    };

    const yOffset = -navbarHeight;
    const element = refs[section].current;
    const y =
      (element?.getBoundingClientRect().top ?? 0) +
      window.pageYOffset +
      yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    if (!initialLoad && loadingState === "done") {
      scrollTo("about");
    }
  }, [initialLoad, loadingState]);

  return (
    <div className="app-container">
      {initialLoad && loadingState !== "done" && (
        <div
          className={`loading-overlay ${
            loadingState === "sliding" ? "slide-down" : ""
          }`}
        >
          <LoadingPage />
        </div>
      )}
      <div
        className={`main-content ${
          !initialLoad || loadingState === "done" ? "visible" : ""
        }`}
      >
        <NavBar activeSection={activeSection} scrollTo={scrollTo} />
        <div className="content-container">
          <section ref={aboutRef} className="full-page-section">
            <About />
          </section>
          <section ref={experienceRef} className="full-page-section">
            <Experience />
          </section>
          <section ref={portfolioRef} className="full-page-section">
            <Portfolio />
          </section>
        </div>
        <button
          className="dark-mode-toggle"
          onClick={darkModeHandler}
          style={{ opacity: 0, position: "absolute", zIndex: -1 }}
        >
          Toggle Dark Mode
        </button>
      </div>
    </div>
  );
}

export default App;
