import React from "react";
import { FaReact, FaJs, FaNodeJs, FaPython, FaDocker } from "react-icons/fa";
import { SiTypescript, SiMongodb } from "react-icons/si";
import { GrMysql } from "react-icons/gr";
import "./Portfolio.css";

const ShinyText = ({ children, className = "" }) => (
  <span className={`shiny ${className}`}>{children}</span>
);

const techIcons = [
  { name: "React", Icon: FaReact },
  { name: "JavaScript", Icon: FaJs },
  { name: "TypeScript", Icon: SiTypescript },
  { name: "Node.js", Icon: FaNodeJs },
  { name: "Python", Icon: FaPython },
  { name: "Docker", Icon: FaDocker },
  { name: "MySQL", Icon: GrMysql },
  { name: "MongoDB", Icon: SiMongodb },
];

const TechCarousel = () => {
  const iconWidth = 100;
  const gap = 16;
  const totalWidth = techIcons.length * (iconWidth + gap);

  return (
    <div className="overflow-hidden mb-8 bg-gray-100 dark:bg-[hsl(0,0%,20%)] p-4 rounded-lg">
      <style>{`
        @keyframes scroll {
          0% { transform: translateX(0); }
          100% { transform: translateX(-${totalWidth}px); }
        }
      `}</style>
      <div
        className="flex"
        style={{
          animation: `scroll ${techIcons.length * 3}s linear infinite`,
          width: `${totalWidth * 2}px`,
        }}
      >
        {[...techIcons, ...techIcons].map((tech, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center mx-2 flex-shrink-0"
            style={{ width: `${iconWidth}px` }}
          >
            <tech.Icon className="text-4xl mb-2" />
            <div className="text-sm">{tech.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Portfolio = () => {
  return (
    <div className="min-h-screen transition-colors duration-300">
      <style jsx global>{`
        .shiny {
          background-image: linear-gradient(
            90deg,
            rgba(167, 199, 231, 0.9) 0%,
            rgba(69, 242, 252, 0.9) 100%
          );
          background-position: 0 -100%;
          background-size: 200% auto;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          transition: background-position 1000ms ease-out;
        }
        .group:hover .shiny {
          background-position: 100% 100%;
        }
      `}</style>
      <div className="container mx-auto px-4 py-8">
        <TechCarousel />
        <div className="text-center my-16">
          <h2 className="text-5xl font-bold mb-4">
            <ShinyText>Portfolio</ShinyText>
          </h2>
          <p className="text-3xl font-semibold">
            <ShinyText>Is Under Construction</ShinyText>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
