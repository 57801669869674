import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardFooter,
} from "../components/ui/card";
import { Badge } from "../components/ui/badge";

const studies = {
  title: "M.Sc. Industrial Engineering & Management",
  school: "Lund University",
  url:
    "https://lup.lub.lu.se/luur/download?func=downloadFile&recordOId=9114697&fileOId=9114698",
  range: "September 2019 - June 2023",
  description:
    "Master in Supply Chain & specialization in Applied Mathematics.",
};

const jobs = [
  {
    title: "Supply Chain Developer",
    company: "Skånemejerier",
    url: "https://foretag.skanemejerier.se/",
    range: "June 2023 - Present",
    description:
      "Responsible for improvements in the Supply Chain organization. My role spans the entire project lifecycle: from initial concept to final implementation. This includes crafting compelling business cases for CapEx applications. Notable achievement was optimizing our 3PL operations, resulting in a 25% annual budget saving, mittigating costs of 3.5 MSEK anually by  achieving cost control over pallets, and integrating a route optimization tool in the ERP.",
    skills: [
      "Project Management",
      "Supply Chain",
      "Python",
      "SQL",
      "Node JS",
      "MongoDB",
      "Data Science",
      "Applied Mathematics",
      "QlikSense",
    ],
  },
  {
    title: "Business Planning Intern",
    company: "Procter & Gamble",
    url: "https://us.pg.com/",
    range: "June 2022 - August 2022",
    description:
      "Managed day-to-day operations for the Heads & Shoulders category across the Nordic market, ensuring on-time delivery, inventory optimization, and customer satisfaction.",
    skills: ["Supply Chain", "Power BI", "Data Analysis", "Optimization"],
  },
  {
    title: "Purchasing Intern",
    company: "ICA Gruppen",
    url: "https://www.icagruppen.se/",
    range: "June 2019 - August 2021",
    description:
      "Worked here three consecutive years, only during summer months. Managed daily operations ensuring a service level >95% for several different product categories.",
    skills: ["Supply Chain", "Excel VBA", "Data Analysis", "Purchasing"],
  },
];

const ClickableCard = ({ href, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="block transition-all duration-300 hover:shadow-lg hover:-translate-y-1 group"
  >
    <Card className="mb-4 cursor-pointer overflow-hidden">{children}</Card>
  </a>
);

const ShinyText = ({ children, className }) => (
  <span className={`shiny ${className}`}>{children}</span>
);

const Experience = () => {
  return (
    <div className="max-w-3xl mx-auto p-4">
      <style jsx global>{`
        .shiny {
          background-image: linear-gradient(
            90deg,
            rgba(167, 199, 231, 0.9) 0%,
            rgba(69, 242, 252, 0.9) 100%
          );
          background-position: 0 -100%;
          background-size: 200% auto;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          transition: background-position 1000ms ease-out;
        }
        .group:hover .shiny {
          background-position: 100 100;
        }
      `}</style>
      <h2 className="text-2xl font-bold mb-4">Where I've Studied</h2>
      <ClickableCard href={studies.url}>
        <CardHeader>
          <h3 className="text-xl font-semibold">
            <ShinyText>{studies.title}</ShinyText>{" "}
            <span>
              · <ShinyText>{studies.school}</ShinyText>
            </span>
          </h3>
          <p className="text-sm text-gray-500 group-hover:text-gray-700 dark:group-hover:text-gray-300 transition-colors duration-300">
            {studies.range}
          </p>
        </CardHeader>
        <CardContent>
          <p className="group-hover:text-gray-900 dark:group-hover:text-gray-100 transition-colors duration-300">
            {studies.description}
          </p>
        </CardContent>
      </ClickableCard>

      <h2 className="text-2xl font-bold mb-4 ">Where I've Worked</h2>
      {jobs.map((job, index) => (
        <ClickableCard key={index} href={job.url}>
          <CardHeader>
            <h3 className="text-xl font-semibold">
              <ShinyText>{job.title}</ShinyText>{" "}
              <span>
                · <ShinyText>{job.company}</ShinyText>
              </span>
            </h3>
            <p className="text-sm text-gray-500 group-hover:text-gray-700 dark:group-hover:text-gray-300 transition-colors duration-300">
              {job.range}
            </p>
          </CardHeader>
          <CardContent>
            <p className="group-hover:text-gray-900 dark:group-hover:text-gray-100 transition-colors duration-300">
              {job.description}
            </p>
          </CardContent>
          <CardFooter>
            <div className="flex flex-wrap gap-2">
              {job.skills.map((skill, skillIndex) => (
                <Badge key={skillIndex} variant="secondary">
                  {skill}
                </Badge>
              ))}
            </div>
          </CardFooter>
        </ClickableCard>
      ))}
    </div>
  );
};

export default Experience;
